<template>
  <div
    class="c-app"
    :class="{
      'c-dark-theme': darkMode,
      'has-fixed-sidebar': hasFixedSidebar,
      'has-loading-indicator': loading,
    }"
  >
    <CWrapper>
      <transition name="fade" mode="out-in">
        <div v-if="loading" id="loading-indicator" class="loader-container">
          <div class="loader">
            <div class="loading-bar"></div>
          </div>
        </div>
      </transition>
      <TheHeader />
      <div class="c-body">
        <main class="c-main">
          <IdlePrompt />
          <CContainer fluid>
            <transition name="fade" mode="out-in">
              <router-view :key="$route.path"></router-view>
            </transition>
          </CContainer>
        </main>
      </div>
      <TheFooter />
    </CWrapper>
  </div>
</template>

<script>
import TheHeader from './TheHeader';
import TheFooter from './TheFooter';
import IdlePrompt from '@/components/modal/IdlePrompt';
import { mapGetters } from 'vuex';
export default {
  name: 'TheContainer',
  components: {
    TheHeader,
    TheFooter,
    IdlePrompt,
  },
  data() {
    return {
      hasFixedSidebar: true,
    };
  },
  computed: {
    ...mapGetters('coreui', ['darkMode']),
    ...mapGetters('loader', ['loading']),
  },
};
</script>

<style scoped lang="scss">
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.loader-container {
  position: sticky;
  width: 100%;
  top: 0;
  z-index: 9999;
  animation-duration: 1s;
}
.loader {
  position: relative;
  width: 100%;

  .loading-bar {
    position: absolute;
    width: 100%;
    height: 5px;
    border-radius: 5px;

    &:before {
      content: '';
      display: block;
      position: absolute;
      width: 0;
      height: 100%;
      background-color: #39f;
      box-shadow: 5px 0px 8px 0px #39f;
      animation: load 3s linear infinite;
    }
  }
}
@keyframes load {
  0% {
    left: 0%;
    right: 100%;
    width: 0%;
  }
  10% {
    left: 0%;
    right: 75%;
    width: 40%;
  }
  90% {
    right: 0%;
    left: 75%;
    width: 40%;
  }
  100% {
    left: 100%;
    right: 0%;
    width: 0%;
  }
}
</style>
