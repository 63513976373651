<template>
  <CModal
    class="clien-tax-treatment-type-modal z-index-max"
    :show.sync="show"
    centered
    :close-on-backdrop="false"
  >
    <template #header>Are you here?</template>
    You will be logged out due to inactivity in {{ countDown }} seconds
    <template #footer>
      <CButton id="stay" color="primary" @click="handleStayLoggedIn"
        >Stay Logged In
      </CButton>
      <CButtonToolbar>
        <CButton
          id="leave"
          class="mr-1"
          color="secondary"
          @click="handleLogout"
        >
          Logout
        </CButton>
      </CButtonToolbar>
    </template>
  </CModal>
</template>
<script>
import store from '@/store';
import { VUE_APP_JWT_AUTH_MAX_IDLE_TIME } from '@/EnvVars';

export default {
  name: 'IdlePromptModal',
  data() {
    return {
      countDown: 0,
      lastActivityDispatch: new Date(),
      timeOut: null,
    };
  },
  computed: {
    show() {
      return store.getters['auth/showIdlePrompt'];
    },
  },
  watch: {
    show(isShown) {
      if (isShown) {
        const lastActivity = store.getters['auth/lastActivity'];
        const delta = new Date().getTime() - lastActivity;
        let countdown = Math.round(
          (VUE_APP_JWT_AUTH_MAX_IDLE_TIME - delta) / 1000,
        );
        this.countDown = countdown > 10 ? countdown : 30;
      }
    },
    countDown: {
      handler(value) {
        if (value > 0) {
          this.timeOut = setTimeout(() => {
            this.countDown--;
          }, 1000);
        } else {
          store.dispatch('auth/logout');
        }
      },
    },
  },
  created() {
    window.addEventListener('keyup', this.updateActivity);
    window.addEventListener('mousemove', this.updateActivity);
  },
  destroyed() {
    window.removeEventListener('keyup', this.updateActivity);
    window.removeEventListener('mousemove', this.updateActivity);
  },
  methods: {
    updateActivity() {
      const delta = new Date().getTime() - this.lastActivityDispatch.getTime();
      if (delta >= 5000) {
        this.lastActivityDispatch = new Date();
        store.dispatch('auth/updateLastActivity');
      }
    },
    handleLogout() {
      store.dispatch('auth/logout');
    },
    handleStayLoggedIn() {
      clearTimeout(this.timeOut);
      store.dispatch('auth/updateLastActivity');
      store.dispatch('auth/refreshToken');
      store.dispatch('auth/idlePrompt', false);
    },
  },
};
</script>
<style>
.z-index-max > div {
  z-index: 4000 !important;
}
.z-index-max > .modal {
  z-index: 4001 !important;
}
</style>
