<template>
  <CHeader with-subheader>
    <CHeaderBrand
      style="
        margin-left: 1rem;
        margin-right: 2rem;
        margin-top: auto;
        margin-bottom: auto;
      "
      to="/"
    >
      <img
        name="logo"
        height="40"
        :alt="$t('menu.pulseNav')"
        :src="$brand('modios-pulse-nav.png', '/img')"
      />
    </CHeaderBrand>
    <CHeaderNav class="d-md-down-none mr-auto">
      <CHeaderNavItem class="px-3">
        <CHeaderNavLink to="/dashboard">
          {{ $t('menu.dashboard') }}
        </CHeaderNavLink>
      </CHeaderNavItem>
      <CHeaderNavItem v-if="rapidInsight" class="px-3">
        <CHeaderNavLink to="/rapid-insight"
          >{{ $t('menu.rapidInsight') }}
        </CHeaderNavLink>
      </CHeaderNavItem>
      <CHeaderNavItem v-if="rapidTesting" class="px-3">
        <CHeaderNavLink to="/testing">
          {{ $t('menu.testing') }}
        </CHeaderNavLink>
      </CHeaderNavItem>
      <CHeaderNavItem v-if="rapidVerify" class="px-3">
        <CHeaderNavLink :to="{ name: 'RapidVerifyDashboard' }">
          {{ $t('menu.verify') }}
        </CHeaderNavLink>
      </CHeaderNavItem>
      <CHeaderNavItem v-if="pma" class="px-3">
        <CHeaderNavLink href="/pma/">
          {{ $t('menu.pma') }}
        </CHeaderNavLink>
      </CHeaderNavItem>
    </CHeaderNav>
    <CHeaderNav>
      <div class="px-3">
        {{ user.fullName }}<br />
        <span class="text-muted text-small">{{ tenantName }}</span>
      </div>
      <TheHeaderDropdownAccnt />
    </CHeaderNav>
  </CHeader>
</template>

<script>
import TheHeaderDropdownAccnt from './TheHeaderDropdownAccnt';
import { mapGetters } from 'vuex';

export default {
  name: 'TheHeader',
  components: {
    TheHeaderDropdownAccnt,
  },
  props: {
    hideAside: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters('coreui', ['darkMode']),
    ...mapGetters('auth', [
      'isAdmin',
      'tenantName',
      'user',
      'rapidInsight',
      'rapidTesting',
      'rapidVerify',
      'pma',
    ]),
  },
};
</script>
<style lang="scss">
.header-row {
  width: 100%;
  margin: 5px;
  margin-top: 10px;
  margin-right: 10px;
}
</style>
