<script>
import { mapGetters } from 'vuex';
import { isEmpty } from 'lodash';
import eventBus from '../utils/eventBus';
import { VUE_APP_CHARGEBEE_PORTAL } from '@/EnvVars';

export default {
  name: 'TheHeaderDropdownAccnt',
  data() {
    return {
      itemsCount: 42,
    };
  },
  computed: {
    ...mapGetters('auth', ['user', 'uiConfig']),
    supportLink() {
      //return SUPPORT_CONFIG?.link;
      return this.uiConfig?.menu?.help?.support?.link;
    },
    displayHelpLink() {
      return !isEmpty(this.uiConfig?.menu?.help?.help);
    },
    displaySupportLink() {
      return !isEmpty(this.uiConfig?.menu?.help?.support);
    },
    displayBillingAccountLink() {
      return !!this.user.chargebeeCustomerId && VUE_APP_CHARGEBEE_PORTAL;
    },
    billingAccountLink() {
      return VUE_APP_CHARGEBEE_PORTAL;
    },
  },
  methods: {
    handleClickHamburger() {
      eventBus.$emit('freshDesk.close');
    },
    handleClickSupport() {
      eventBus.$emit('freshDesk.open');
    },
  },
};
</script>

<template>
  <CDropdown
    in-nav
    class="c-header-nav-items"
    placement="bottom-end"
    add-menu-classes="pt-0"
  >
    <template #toggler>
      <CHeaderNavLink @click.native="handleClickHamburger">
        <CIcon style="width: 40px; height: 40px" name="cid-hamburger-menu" />
      </CHeaderNavLink>
    </template>
    <CDropdownHeader tag="div" class="text-center" color="light">
      <strong>Menu</strong>
    </CDropdownHeader>
    <CDropdownItem
      v-if="displayBillingAccountLink"
      :href="billingAccountLink"
      target="_blank"
      >Billing Account</CDropdownItem
    >
    <CDropdownItem v-if="displayHelpLink" @click="handleClickSupport"
      >Help
    </CDropdownItem>
    <CDropdownItem v-if="displaySupportLink" :href="supportLink"
      >Support
    </CDropdownItem>
    <CDropdownDivider v-if="displaySupportLink || displayHelpLink" />
    <CDropdownItem @click="$store.dispatch('auth/logout')">
      <CIcon name="cil-lock-locked" /> Logout
    </CDropdownItem>
  </CDropdown>
</template>

<style scoped>
.c-icon {
  margin-right: 0.3rem;
}
</style>
